export const SET_GENDER = 'SET_GENDER';
export const SET_GOAL = 'SET_GOAL';
export const SET_CURRENT_BODY = 'SET_CURRENT_BODY';
export const SET_SLEEP_DURATION = 'SET_SLEEP_DURATION';
export const SET_TARGET_BODY = 'SET_TARGET_BODY';
export const SET_TARGET_ZONE = 'SET_TARGET_ZONE';
export const SET_ACTIVITY_LVL = 'SET_ACTIVITY_LVL';
export const SET_TARGET_WEIGHT = 'SET_TARGET_WEIGHT';
export const SET_CURRENT_WEIGHT = 'SET_CURRENT_WEIGHT';
export const SET_AGE = 'SET_AGE';
export const SET_TALL = 'SET_TALL';
export const SET_MEASURE_SYSTEM = 'SET_MEASURE_SYSTEM';
export const SET_ONBOARDING_SCREENS_LIST = 'SET_ONBOARDING_SCREENS_LIST';
export const INIT_AUTO_LOGIN = 'INIT_AUTO_LOGIN';
export const SET_URL_PARAMS = 'SET_URL_PARAMS';
export const SET_OCCASION = 'SET_OCCASION';
export const SET_OCCASION_DATE = 'SET_OCCASION_DATE';
export const SET_FITNESS_LVL = 'SET_FITNESS_LVL';
export const SET_AGE_RANGE = 'SET_AGE_RANGE';
export const SKIP_ONBOARDING = 'SKIP_ONBOARDING';
export const SET_SKIP_ONBOARDING_STATE = 'SET_SKIP_ONBOARDING_STATE';
export const SET_CURRENT_MOOD = 'SET_CURRENT_MOOD';
export const SET_STRESS_FACTORS = 'SET_STRESS_FACTORS';
export const SET_SLEEP_PROBLEMS = 'SET_SLEEP_PROBLEMS';
export const SET_OCCASION_RESULT_CONFIG = 'SET_OCCASION_RESULT_CONFIG';
export const SET_FOCUS = 'SET_FOCUS';
export const SET_BODY_IMAGE = 'SET_BODY_IMAGE';
export const SET_REASON = 'SET_REASON';
export const SET_REWARD = 'SET_REWARD';
